@use '../../../scss/vars' as *;

#offering #component-list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  left: 0;
  overflow: inherit;
  padding-left: 0;
  position: fixed;
  top: 56px;

  h6.sidebar-title {
    padding-left: 15px;
  }

  .no-components {
    color: $gray-light;
    font-weight: 500;
    padding-top: 60px;
    text-align: center;
  }

  .list {
    clear: both;
  }
}
