@use '../../../../scss/vars' as *;

.canvas-component.canvas-component-image {
  .add-image {
    display: block;
    margin: 44px auto;
  }

  .carousel {
    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
    }

    a.carousel-control-prev,
    a.carousel-control-next {
      cursor: pointer;
      opacity: 1;
      width: 10%;

      &:hover {
        span.carousel-control-prev-icon,
        span.carousel-control-next-icon {
          &::before {
            color: $primary;
          }
        }
      }

      span.carousel-control-prev-icon,
      span.carousel-control-next-icon {
        background-image: none;
        height: 48px;
        width: 24px;

        &::before {
          color: #fff;
          font-family: 'Font Awesome 5 Free';
          font-size: 3rem;
          font-weight: 600;
          text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
          transition: color 0.2s ease-in-out;
        }
      }

      span.carousel-control-prev-icon {
        &::before {
          content: '\f104';
        }
      }

      span.carousel-control-next-icon {
        &::before {
          content: '\f105';
        }
      }
    }
  }

  .image-description {
    padding-top: 5px;
    text-align: center;
  }
}
