#inspector-sources-and-uses {
  button.toggle {
    svg {
      font-size: 18px;
      margin-right: 4px;
      margin-top: -4px;
      vertical-align: middle;
      width: 15px;
    }
  }

  .toggle-options {
    padding-left: 20px;
    padding-top: 10px;
  }
}
