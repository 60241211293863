@use '../../../../scss/vars' as *;

.file-modal {
  label:first-child {
    margin-top: 15px;
  }

  .modal-body > div:first-child label:first-child {
    margin-top: 0;
  }

  .custom-file {
    margin-bottom: 10px;

    label.custom-file-label {
      margin: 0;
    }
  }

  .current-item {
    label {
      display: block;
    }

    button.current-doc-link {
      padding: 0;

      &:not(.disabled):hover {
        .file-icon {
          color: $gray-med;
        }
      }

      &:focus {
        outline: none;
      }

      &.disabled {
        cursor: default;
      }

      .file-icon {
        color: $gray-med;
        height: 3rem;
        transition: color 0.2s ease-in-out;
        vertical-align: top;
        width: 3rem;
      }
    }

    img {
      display: block;
      max-height: 200px;
      max-width: 400px;
    }
  }

  .hide-description {
    label > span {
      display: block;
    }

    .form-check-input {
      margin: 6px 0 0;
    }

    .label-subtext {
      display: inline-block;
      padding-left: 20px;
    }
  }

  .custom-width {
    input[type='number'] {
      appearance: textfield;
      width: 70px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }

  .hero-form-contents {
    .left-gradient {
      label > span {
        display: block;
      }

      .form-check-input {
        margin: 6px 0 0;
      }

      .label-subtext {
        display: inline-block;
        padding-left: 20px;
      }
    }
  }
}
