@use 'sass:math';

#component-list {
  $row-padding: 10px;

  .rowWrapper {
    cursor: move;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }
  }

  .rowWrapperDragDisabled {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  .row {
    display: block;
    height: 100%;
    white-space: no-wrap;

    & > * {
      box-sizing: border-box;
    }
  }

  /**
  * The outline of where the element will go if dropped, displayed while dragging
  */
  .rowLandingPad {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;

    * {
      opacity: 0 !important;
    }

    &::before {
      background-color: lightblue;
      border: 3px dashed white;
      content: '';
      inset: 0 0 0 -12px;
      position: absolute;
      z-index: -1;
    }
  }

  /**
  * Alternate appearance of the landing pad when the dragged location is invalid
  */
  .rowCancelPad {
    @extend .rowLandingPad;

    &::before {
      background-color: #e6a8ad;
    }
  }

  /**
  * Nodes matching the search conditions are highlighted
  */
  .rowSearchMatch {
    outline: solid 3px #0080ff;
  }

  /**
  * The node that matches the search conditions and is currently focused
  */
  .rowSearchFocus {
    outline: solid 3px #fc6421;
  }

  %rowItem {
    display: inline-block;
    vertical-align: middle;
  }

  .rowContents {
    @extend %rowItem;

    align-items: center;
    background-color: white;
    border: solid #bbb 1px;
    border-radius: 2px;
    box-shadow: 0 2px 2px -2px;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    justify-content: space-between;
    min-width: 230px;
    padding: 0 5px 0 10px;
    position: relative;
  }

  .rowLabel {
    @extend %rowItem;

    flex: 0 1 auto;
    padding-right: 20px;
  }

  .rowToolbar {
    @extend %rowItem;

    display: flex;
    flex: 0 1 auto;
  }

  .moveHandle {
    @extend %rowItem;

    background: #d9d9d9
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
      no-repeat center;
    border: solid #aaa 1px;
    border-radius: 1px;
    box-shadow: 0 2px 2px -2px;
    cursor: move;
    height: 100%;
    width: 20px;
    z-index: 1;
  }

  .loadingHandle {
    @extend .moveHandle;

    background: #d9d9d9;
    cursor: default;
  }

  @keyframes pointFade {
    0%,
    19.999%,
    100% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }
  }

  .loadingCircle {
    height: 80%;
    margin: 10%;
    position: relative;
    width: 80%;
  }

  .loadingCirclePoint {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    $point-count: 12;
    $spin-animation-time: 800ms;

    &::before {
      animation: pointFade $spin-animation-time infinite ease-in-out both;
      background-color: #fff;
      border-radius: 30%;
      content: '';
      display: block;
      height: 30%;
      margin: 0 auto;
      width: 11%;
    }

    @for $i from 1 through (math.round(calc(($point-count + 1) / 2))) {
      &:nth-of-type(#{$i}) {
        transform: rotate(calc(360deg / $point-count * ($i - 1)));
      }

      &:nth-of-type(#{$i + calc($point-count / 2)}) {
        transform: rotate(calc(180deg + 360deg / $point-count * ($i - 1)));
      }

      &:nth-of-type(#{$i}),
      &:nth-of-type(#{$i + calc($point-count / 2)}) {
        &::before {
          animation-delay: -$spin-animation-time +
            (calc($spin-animation-time / $point-count) * 2 * ($i - 1));
        }
      }
    }
  }

  .toolbarButton {
    @extend %rowItem;
  }

  .rowTitle {
    font-weight: bold;
  }

  .rowTitleWithSubtitle {
    display: block;
    font-size: 85%;
    height: 0.8rem;
  }

  .rowSubtitle {
    font-size: 70%;
    line-height: 1;
  }

  .collapseButton,
  .expandButton {
    appearance: none;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #000;
    cursor: pointer;
    height: 16px;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;

    &:focus {
      box-shadow:
        0 0 0 1px #000,
        0 0 1px 3px #83bef9;
      outline: none;
    }

    &:hover:not(:active) {
      background-size: 24px;
      height: 20px;
      width: 20px;
    }
  }

  .collapseButton {
    background: #fff
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
      no-repeat center;
  }

  .expandButton {
    background: #fff
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
      no-repeat center;
  }

  /**
  * Line for under a node with children
  */
  .lineChildren {
    display: inline-block;
    height: 100%;
    position: absolute;

    &::after {
      background-color: black;
      bottom: 0;
      content: '';
      height: $row-padding;
      left: 50%;
      position: absolute;
      width: 1px;
    }
  }

  .rst__tree {
    overflow: hidden auto;
    padding: 0 0 20px;

    & > .rst__node {
      left: -5px !important;
      width: calc(100% + 5px) !important;
    }

    .rst__lineBlock,
    .rst__lineChildren::after {
      visibility: hidden;
    }

    .rst__nodeContent {
      right: 15px;
    }
  }

  .rst__highlightBottomLeftCorner {
    &::before {
      right: 6px;
    }

    &::after {
      border-left-color: #21b1e6;
      right: -6px;
    }
  }
}
