@use '../../../scss/vars' as *;

#canvas {
  margin-left: 300px;
  min-height: calc(100vh - 56px);
  padding: 35px 15px;
  width: calc(100% - 600px);

  &.inspector-closed {
    width: calc(100% - 321px);
  }

  .no-components {
    padding-top: 145px;
    text-align: center;

    @media (max-height: 799px) {
      padding-top: 80px;
    }

    p {
      color: $gray-light;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }

  .add-component-wrap {
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    width: 200px;
  }
}
