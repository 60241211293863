.canvas-component.canvas-component-table {
  .table-toolbar {
    button {
      &.margin-right {
        margin-right: 15px;
      }

      svg.svg-inline--fa {
        margin-right: 5px;
      }

      span {
        vertical-align: middle;
      }
    }
  }

  table {
    width: calc(100% - 1px);

    p {
      margin: 0;
    }

    td {
      border: 1px solid #ddd;
      padding: 6px;
    }
  }

  .slate-editor {
    overflow: auto;

    &.has-header table tr:first-child td,
    &.has-footer table tr:last-child td,
    &.first-column-is-header table td:first-child {
      background: #f4f7fb;
      border: 1px solid #ddd;

      span {
        font-weight: 700;
      }
    }
  }
}
