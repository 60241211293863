@use '../../scss/vars' as *;

#login {
  .google-login {
    border-top: 1px solid $gray-bg;
    margin-top: 10px;
    padding-top: 25px;
    text-align: center;

    p {
      color: #777;
      font-size: 0.875rem;
    }

    svg {
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));

      #base-background {
        fill: #f4f7fb;
      }
    }
  }
}
