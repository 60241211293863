@use '../../../scss/vars' as *;

#add-connector-modal {
  .connector-info {
    .key {
      background: #f4f7fb;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 10px;
      padding: 20px;

      span {
        color: $gray-med;
        display: inline-block;
        font-weight: 400;
      }
    }
  }

  button.btn-secondary {
    margin-right: 0.25rem;
  }
}
