@use '../../../scss/vars' as *;

.offering-list-item {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;

  &::after {
    clear: both;
    content: '';
    display: block;
  }

  &:hover {
    button {
      display: inline-block;
    }
  }

  .title {
    color: $brand;
    display: inline-block;
    font-weight: 500;
    margin: 7px 15px 0 0;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  button {
    display: none;

    &:hover {
      .svg-inline--fa {
        color: $primary;
      }
    }

    .svg-inline--fa {
      color: $gray-light;
      transition: color 0.2s ease-in-out;
    }
  }

  .btn {
    display: block;
    float: right;
    margin-bottom: 0;
    padding: 6px 20px;
    text-align: center;
    width: 120px;
  }
}
