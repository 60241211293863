@use '../../../../scss/vars' as *;

.canvas-component-sources-and-uses {
  .container {
    padding: 0;

    .col-left {
      padding-right: 7px;
    }

    .col-right {
      padding-left: 7px;
    }

    h6 {
      margin: 10px auto;
    }
  }

  .table {
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    margin-bottom: 0;
    min-width: inherit !important;

    colgroup col {
      width: auto !important;
    }

    td {
      border-right: 1px solid #dee2e6;
      height: 49px;
      overflow: hidden;
      padding: 5px;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: 139px;

      .btn {
        color: #444;
        padding: 10px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          color: $primary;
        }
      }

      &.p-0 {
        max-width: 80px;
        width: 40px;
      }

      .dx-g-bs4-fixed-block {
        text-align: center;
      }
    }
  }

  .summary-row > .total-value {
    float: right;
  }
}
