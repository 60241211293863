#user-control-panel {
  height: 100%;

  .container {
    background: #fff;
    height: 100%;
    padding-top: 50px;
  }

  h1.page-subheader {
    margin-bottom: 50px;
  }

  #user-fields {
    padding: 0 15px;
  }
}
