@use '../../../scss/vars' as *;

#connector-single {
  padding: 40px 40px 40px 10px;

  .details {
    margin-bottom: 30px;

    .detail {
      font-weight: 500;
      margin-bottom: 10px;

      span {
        color: $gray-med;
        display: inline-block;
        font-weight: 700;
      }
    }
  }

  .key {
    background: #f4f7fb;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: 30px;
    padding: 20px;

    span {
      color: $gray-med;
      display: inline-block;
      font-weight: 400;
    }
  }

  .no-connectors {
    color: $gray-light;
    font-size: 1.25rem;
    padding-top: 50px;
    text-align: center;
  }
}
