@use '../../../scss/vars' as *;

.list-item-wrap {
  padding-left: 15px;

  &.collapsed {
    .list-item-wrap {
      display: none;
    }
  }

  .list-item-wrap {
    padding-left: 24px;
  }

  .component-list-item {
    border: 2px solid transparent;
    position: relative;

    &:not(.section) {
      padding-left: 32px;
    }

    &.locked {
      border-color: $gray-bg;
      padding-right: 20px;

      &::before {
        color: $gray-light;
        content: '\f023';
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        line-height: 1;
        position: absolute;
        right: 5px;
        top: 10px;
        z-index: 1;
      }
    }

    .toggle {
      padding: 0 5px 0 0;
      vertical-align: top;

      &:hover {
        outline: none;

        .svg-inline--fa {
          color: $brand;
        }
      }

      &:focus {
        outline: none;
      }

      .svg-inline--fa {
        bottom: 1px;
        color: #797979;
        height: 1.2rem;
        position: relative;
        width: 1.2rem;
      }
    }
  }

  &.in-view {
    &::before {
      color: #498fe1;
      content: '\f105';
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      left: -11px;
      line-height: 1;
      position: absolute;
      top: 13px;
      z-index: 1;
    }
  }
}
