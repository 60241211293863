@use '../../../scss/vars' as *;

#add-component-modal {
  max-width: 844px;

  .modal-body {
    padding: 15px 25px 1px;

    .row > div {
      padding: 0 7px;
    }

    .component-button {
      background: #f4f7fb;
      border-radius: 3px;
      color: #4f5a67;
      display: block;
      height: 130px;
      line-height: 1;
      margin-bottom: 14px;
      position: relative;
      width: 100%;

      &.active {
        background: $primary;
        outline: none;

        .svg-inline--fa,
        .text-icon {
          color: #fff;
        }

        span {
          color: #fff;
        }
      }

      &:hover:not(.active) {
        border-color: $primary;

        .svg-inline--fa,
        .text-icon {
          color: $primary;
        }

        span {
          color: $primary;
        }
      }

      &:focus {
        outline: none;
      }

      .svg-inline--fa {
        height: 2.2rem;
        margin-bottom: 10px;
        transition: color 0.2s ease-in-out;
        width: 2.2rem;

        &.fa-percent {
          height: 2rem;
          margin-bottom: 12px;
        }
      }

      span {
        display: block;
        font-size: 0.8125rem;
        font-weight: 700;
        text-transform: uppercase;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}
