@use '../../scss/vars' as *;

#error-boundary {
  background: #fff;
  padding: 30px;

  h3 {
    margin-bottom: 50px;
  }

  details {
    cursor: pointer;

    pre {
      background: #fbfbfb;
      border: 1px solid $gray-bg;
      border-radius: 4px;
      cursor: default;
      margin-top: 15px;
      padding: 15px;
    }
  }
}
