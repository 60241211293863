@use '../../../../scss/vars' as *;

.canvas-component.canvas-component-section-parallel {
  min-height: 200px;

  .col-left {
    border-right: 1px solid $gray-bg;
    padding-left: 0;
  }

  .col-right {
    padding-right: 0;
  }

  button.add-component-button {
    display: block;
    margin: 0 auto 30px;
  }

  .helper-text {
    color: $gray-light;
    font-size: 0.875rem;
    margin: 30px auto;
    text-align: center;
  }
}
