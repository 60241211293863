@use '../../../scss/vars' as *;

.offering-list {
  .no-offerings {
    color: $gray-light;
    padding: 130px 0;
    text-align: center;

    p {
      font-weight: 500;
    }
  }

  .archive-link {
    display: inline-block;
    margin-top: 20px;
  }
}
