// If this file gets large and it becomes necessary to split, add separate files in /scss directory and import them here

// Imports
@use './scss/font-awesome' as *;
@use './scss/vars' as *;
@use './scss/bootstrap-overrides' as *;

// Fonts

@font-face {
  font-family: Larsseit;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Larsseit-Light_gdi.woff') format('woff');
}

@font-face {
  font-family: Larsseit;
  font-stretch: normal;
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/Larsseit-LightItalic_gdi.woff') format('woff');
}

@font-face {
  font-family: Larsseit;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Larsseit_gdi.woff') format('woff');
}

@font-face {
  font-family: Larsseit;
  font-stretch: normal;
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/Larsseit-Italic_gdi.woff') format('woff');
}

@font-face {
  font-family: Larsseit;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Larsseit-Medium_gdi.woff') format('woff');
}

@font-face {
  font-family: Larsseit;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Larsseit-Bold_gdi.woff') format('woff');
}

// Global

html,
body,
#root,
#wrapper {
  height: 100%;
}

html,
body {
  font-family: $sans;
  font-size: 16px;
}

body {
  background: $gray-bg;
  padding-top: 56px;
}

a,
button {
  transition: all 0.2s ease-in-out;
}

button {
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
}

// Typography

h1.page-subheader {
  background: $gray-med;
  border-bottom: 1px solid #ddd;
  color: #fff;
  font-size: 1.8rem;
  margin: 0 -15px;
  padding: 20px;
}

h6 {
  color: $brand;
}

.medium-weight {
  font-weight: 500;
}

.gray-med {
  color: $gray-med;
}

.zero-margin-bottom {
  margin-bottom: 0 !important;
}

// Layout

.margin-x {
  margin-bottom: 15px;
}

.margin-xx {
  margin-bottom: 30px;
}

.margin-top-x {
  margin-top: 15px;
}

.margin-top-xx {
  margin-top: 30px;
}

.container-narrow {
  max-width: 570px;
}

.flex-vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
}

.flex-horizontal-center {
  display: flex;
  height: 100%;
  justify-content: center;
}

.no-header-padding {
  margin-top: -56px;
}

.overflow-hidden {
  overflow: hidden;
}

.clear-both {
  clear: both;
}

// Card

.card {
  padding: 30px;
  width: 100%;
}

// Button

.btn {
  border-radius: 3px;
  font-weight: 500;
  padding: 7px 30px;

  &.btn-secondary {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &.btn-outline-secondary {
    border-color: $gray-light;
    color: $gray-light;

    &:hover,
    &:focus {
      background-color: $gray-light;
      color: #fff;
    }
  }

  &.disabled {
    cursor: initial;
    opacity: 0.5;
  }
}

// Form

.form-control {
  background: $gray-lightest;
  border: 0;
  border-radius: 3px;
  margin: 5px 0 0;

  &:focus {
    background: $gray-lightest;
    box-shadow: 0 0 0 0.15rem rgba(0, 169, 223, 0.25);
  }

  &:disabled,
  &:not(select):read-only {
    color: $gray-med-light;
    cursor: not-allowed;
  }

  & + .alert {
    margin-top: 5px;
  }
}

label {
  color: $brand;
  font-weight: 500;
  margin: 15px 0 0;

  &:first-child {
    margin-top: 0;
  }

  .label-subtext {
    color: $gray-light;
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: -3px;
  }
}

.alert {
  padding: 6px 15px;
}

.custom-file-label {
  background: $gray-lightest;
  border: 0;
  height: 2.25rem;

  &::after {
    background: $gray-med;
    border: 0;
    color: #fff;
  }
}

.custom-file-input {
  cursor: pointer;
  height: 2.25rem;
}

// Modal

.modal {
  .modal-header {
    background: #4f5a67;
    padding: 15px 20px;

    .close {
      color: #fff;
      font-weight: 300;
      opacity: 1;

      &:hover {
        color: #fff;
        opacity: 0.7;
      }
    }
  }

  .modal-title {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  button.close {
    &:focus {
      outline: none;
    }
  }

  .modal-content {
    background: transparent;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  }

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 570px;
    }

    .modal-body {
      background: #fff;
      padding: 20px;

      @media (min-height: 800px) {
        min-height: 250px;
      }
    }
  }

  .modal-footer {
    background: $gray-bg;
    border: 0;
    padding: 15px 20px;
  }
}

// Alert

.alert {
  border-radius: 3px;
}
