@use '../../../scss/vars' as *;

.canvas-component {
  border: 1px solid transparent;
  margin: 0 auto 30px;
  max-width: 100%;
  padding: 13px;
  position: relative;
  width: 600px;

  &:hover {
    border-color: $gray-bg;
  }

  &.active {
    border: 4px solid $gray-bg;
    padding: 10px;
  }

  &.locked {
    border: 3px solid $gray-bg;
    padding: 7px;

    &::before {
      color: $gray-light;
      content: '\f023';
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      line-height: 1;
      position: absolute;
      right: 7px;
      top: 7px;
      z-index: 1;
    }
  }

  &.loading {
    &::before {
      background-color: rgba(255, 255, 255, 0.8);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    &::after {
      background-image: url('../../../assets/images/loader-blue.svg');
      background-size: 100%;
      content: '';
      display: block;
      height: 40px;
      left: calc(50% - 20px);
      position: absolute;
      top: calc(50% - 20px);
      width: 40px;
      z-index: 2;
    }
  }
}

.bs-popover-right .arrow {
  top: 6px !important;
}
