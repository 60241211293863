.canvas-component.canvas-component-hero {
  button {
    display: block;
    margin: 44px auto;
  }

  .hero-image {
    height: auto;
    min-height: 250px;
    width: 100%;
  }

  .linear-gradient {
    display: inline-block;
    position: relative;
  }

  .linear-gradient::before {
    background-image: linear-gradient(
      90deg,
      #03133c 0%,
      #03133cb2 60%,
      #03133c00 100%
    );
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .hero-sub-content {
    bottom: 12px;
    color: #fff;
    margin: 20px;
    position: absolute;

    h2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    img {
      height: 100px;
      width: auto;
    }
  }
}
