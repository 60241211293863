.canvas-component.canvas-component-section {
  &.active {
    min-height: 200px;
  }

  .section-title-input {
    border: 0;
    font-size: 2rem;
    font-weight: 700;
    resize: none;
    width: 100%;

    &.depth-1 {
      font-size: 1.75rem;
    }

    &.depth-2 {
      font-size: 1.5rem;
    }

    &.depth-3 {
      font-size: 1.25rem;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    display: block;
    margin: 40px auto;
  }
}
