#connectors {
  height: 100%;

  .container {
    background: #fff;
    height: calc(100vh - 56px);

    .row {
      height: 100%;
    }
  }

  .list-column {
    height: 100%;
    padding-left: 0;
  }
}
