#inspector-cash-flow {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  input[type='checkbox']#loadCumulative {
    margin-top: 22px;
  }
}
