@use '../../../scss/vars' as *;

#inspector-wrap {
  height: calc(100% - 56px);
  position: fixed;
  right: 0;
  top: 56px;

  #inspector.sidebar {
    height: 100%;

    &.closed {
      width: 10px;

      h6.sidebar-title,
      .inspector-fields,
      .add-button {
        display: none;
      }
    }

    #inspector-toggle {
      background: $gray-bg;
      border-bottom-left-radius: 4px;
      left: -24px;
      padding: 8px;
      position: absolute;
      top: 0;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $primary;
      }
    }

    label {
      font-weight: 400;
    }

    .selectable-list-item .label {
      width: calc(100% - 79px);
    }

    .offering-status-alert {
      padding: 4px 10px;
      text-align: center;
      width: 100px;
    }

    &.locked {
      label,
      input,
      select,
      .static-value {
        color: $gray-light;
      }
    }

    .inspector-fields {
      clear: both;

      & > div:first-child > label {
        margin-top: 0;
      }
    }

    .checkbox-group {
      margin: 0 0 0 5px;

      label {
        font-weight: normal;
        margin-left: 5px;
      }
    }

    .locked-block {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      .fa-lock {
        color: $gray-med;
        height: 1.5em;
        margin-bottom: 10px;
        width: 1.5em;
      }

      .text {
        padding: 15px;

        p {
          color: $gray-med;
        }
      }
    }

    p.no-data {
      padding: 50px 0;
      text-align: center;
    }
  }
}
