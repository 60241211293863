@use '../../../../scss/vars' as *;

.key-metrics-modal {
  .icon-select {
    color: #999;

    .icon-button {
      cursor: pointer;
      display: inline-block;
      margin: 0 5px 5px 0;
      padding: 5px;

      &:hover {
        color: #444;
      }

      &.active {
        color: $primary;
      }

      .svg-inline--fa {
        height: 1.5rem;
        transition: color 0.2s ease-in-out;
        width: 1.5rem;
      }
    }
  }
}
