#nothing-found {
  flex-wrap: wrap;

  h2 {
    flex: 0 0 100%;
  }

  a {
    align-self: flex-start;
  }
}
