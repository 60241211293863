@use '../../../scss/vars' as *;

#connector-list {
  border-right: 1px solid #ddd;
  height: 100%;
  overflow-y: auto;
  text-align: center;

  .item {
    border-bottom: 1px solid $gray-bg;
    display: block;
    padding: 20px;
    text-align: left;
    transition: all 0.2s ease-in-out;
    width: 100%;

    &:nth-child(2) {
      border-top: 1px solid $gray-bg;
    }

    &.active {
      background: #f4f7fb;
    }

    &:hover,
    &:focus {
      background: #f4f7fb;
      outline: none;
    }

    .title {
      font-size: 1.125rem;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
    }

    .date {
      color: #999;
      font-size: 0.825rem;
    }
  }

  .btn {
    margin: 30px auto;
  }
}
