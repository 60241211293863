#loading-mask {
  background: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  .loader {
    background-image: url('../../assets/images/loader-blue.svg');
    background-size: 100%;
    display: block;
    height: 80px;
    left: calc(50% - 40px);
    position: absolute;
    top: calc(50% - 40px);
    width: 80px;
  }
}
