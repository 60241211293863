.btn {
  min-height: 40px;

  &.loading {
    background-image: url('../../assets/images/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 70%;
    cursor: wait;
  }
}
