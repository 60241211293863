.map-marker-label {
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin-top: -34px !important;
  padding: 3px !important;
  text-align: center;
  transform: translateX(-50%);

  &.active {
    font-size: 1.2rem;
    margin-top: -46px !important;
    padding: 4px !important;
  }
}
