// Fonts

$sans: 'Larsseit', sans-serif;

// Colors

$brand: #4f5a67;
$primary: #00a9df;
$gray-lightest: #f7f8fb;
$gray-bg: #e8ebef;
$gray-light: #b8c2cc;
$gray-med-light: #a2afbc;
$gray-med: #75828e;
$red: #dc3545;
