@use '../../scss/vars' as *;

#archived-offerings {
  background: #fff;
  min-height: 100%;

  .archived-offerings-list {
    padding: 50px 0;

    .archived-offering {
      border-bottom: 1px solid #ddd;
      padding: 25px 0;

      .title {
        color: $brand;
        display: inline-block;
        font-weight: 500;
      }

      .btn {
        float: right;
      }
    }
  }
}
