@use '../../scss/vars' as *;

#offering {
  background: #fff;
  min-height: 100%;

  .sidebar {
    background: $gray-bg;
    padding: 15px 10px;
    width: 300px;

    .sidebar-title {
      color: $brand;
      float: left;
      font-size: 0.875rem;
      font-weight: 700;
      margin-bottom: 30px;
      padding: 0 5px;
      text-transform: uppercase;
    }

    .form-control {
      background: #fff;

      &:focus {
        background: #fff;
      }
    }

    .add-button {
      padding: 0;
      position: absolute;
      right: 10px;
      top: 14px;
      z-index: 2;

      .svg-inline--fa {
        height: 1.125rem;
        width: 1.125rem;
      }

      &:hover {
        .svg-inline--fa {
          color: $primary;
        }
      }

      &:hover,
      &:focus {
        outline: none;
      }

      .svg-inline--fa {
        color: $gray-med;
        transition: all 0.2s ease-in-out;
        vertical-align: top;
      }
    }

    .selectable-list-item {
      border-radius: 3px;
      cursor: pointer;
      padding: 6px 20px 6px 6px;
      position: relative;

      &.locked,
      &.section {
        .label {
          width: calc(100% - 50px);
        }
      }

      &:hover {
        background: #dee2e6;

        .label {
          &::after {
            background: linear-gradient(
              to left,
              rgba(222, 226, 230, 1) 0%,
              rgba(222, 226, 230, 0) 100%
            );
          }
        }

        button.delete {
          display: block;

          &:hover {
            color: $red;
          }
        }

        button.edit {
          display: block;

          &:hover .component-icon {
            color: $primary;
          }
        }
      }

      &.active {
        background: #d8dce0;

        .label {
          &::after {
            background: linear-gradient(
              to left,
              rgba(216, 220, 224, 1) 0%,
              rgba(216, 220, 224, 0) 100%
            );
          }
        }

        button.delete,
        button.edit {
          display: block;
        }
      }

      .label {
        color: $brand;
        display: inline-block;
        font-size: 0.8125rem;
        font-weight: 500;
        overflow: hidden;
        position: relative;
        top: 4px;
        width: calc(100% - 30px);

        &::after {
          background: linear-gradient(
            to left,
            rgba(232, 235, 239, 1) 0%,
            rgba(242, 242, 242, 0) 100%
          );
          bottom: 0;
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          width: 15px;
        }
      }

      button.delete {
        color: $gray-med;
        display: none;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        right: 6px;
        top: 8px;

        &:hover {
          color: $red;
        }

        &:focus {
          outline: none;
        }
      }

      button.edit {
        display: none;
        position: absolute;
        right: 22px;
        top: 5px;

        .component-icon {
          height: 0.7rem;
          opacity: 1;
          width: 0.7rem;
        }

        &:focus {
          outline: none;
        }
      }

      .component-icon {
        color: $gray-med;
        height: 0.8rem;
        margin-right: 6px;
        margin-top: 7px;
        opacity: 0.6;
        position: relative;
        transition:
          color 0.2s ease-in-out,
          opacity 0.2s ease-in-out;
        vertical-align: top;
        width: 0.8rem;

        &.fa-percent {
          height: 0.7rem;
          top: 1px;
        }

        &.fa-font {
          bottom: 1px;
          height: 0.8rem;
        }
      }
    }
  }

  .editor-toolbar {
    background: $gray-lightest;
    border-bottom: 2px solid #ececec;
    margin-bottom: 10px;
    padding: 5px;

    button {
      color: #444;
      line-height: 1;
      margin-right: 5px;
      padding: 5px;
      vertical-align: middle;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $primary;
      }

      &.toggle-button {
        color: #aaa;

        &:hover {
          color: #777;
        }

        &.active {
          color: #555;
        }
      }

      &.add,
      &.remove {
        .svg-inline--fa {
          height: 0.8rem;
          width: 0.8rem;
        }
      }

      &.button-tab {
        .svg-inline--fa {
          border-right: 2px solid;
        }
      }
    }
  }

  .fixed-toolbar {
    padding-top: 54px;

    .editor-toolbar {
      position: fixed;
      top: 56px;
      width: 572px;

      &::after {
        background: #fff;
        bottom: -7px;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  // Prevent horizontal scrollbar when edit column is removed
  .grid-table-wrap {
    .table-responsive {
      overflow-x: hidden;
    }

    &.active {
      .table-responsive {
        overflow-x: auto;
      }
    }
  }
}
