@use '../../../../scss/vars' as *;

.canvas-component.canvas-component-text {
  h6 {
    color: #212529;
  }

  blockquote {
    background: #f6f6f6;
    border-left: 5px solid #ddd;
    padding: 7px 7px 7px 10px;
  }
}
