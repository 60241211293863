@use '../../../../scss/vars' as *;

#inspector #inspector-metrics {
  .selectable-list-item {
    .connector-info {
      .connector-icon {
        color: #868e96;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        right: 52px;
        top: 7px;
      }
    }
  }
}
