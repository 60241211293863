.canvas-component-sources-and-uses {
  input[type='number'] {
    appearance: textfield;
    min-width: 100px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}
