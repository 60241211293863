.component-title {
  font-size: 2rem;

  &.depth-1 {
    font-size: 1.75rem;
  }

  &.depth-2 {
    font-size: 1.5rem;
  }

  &.depth-3 {
    font-size: 1.25rem;
  }

  &.depth-4 {
    font-size: 1rem;
  }
}
