@use '../../scss/vars' as *;

#user-menu {
  float: right;

  .user-link {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 40px;
    margin-top: 2px;
    padding: 0;
    text-transform: uppercase;
    transition: opacity 0.2s ease-in-out;
    width: 40px;

    &:hover {
      background-color: #6c757d;
      opacity: 0.8;
    }
  }

  .dropdown-menu {
    .user-info {
      color: $gray-med;
      cursor: default;
      font-size: 0.875rem;
      padding: 6px 15px;

      &:hover,
      &:focus {
        background: none;
      }
    }

    button.dropdown-item:not(.user-info) {
      color: $primary;
      padding: 0;

      &.logout {
        padding: 6px 15px;
      }

      &:hover,
      &:active {
        background: $primary;
        color: #fff;

        a {
          color: #fff;
        }
      }

      a {
        display: block;
        padding: 6px 15px;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }

      .svg-inline--fa {
        height: 14px;
        margin: 0 0 2px 12px;
      }
    }
  }
}
