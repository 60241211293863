@use '../../../../scss/vars' as *;

.table-import {
  .button-outline-secondary {
    margin-bottom: 10px;
    min-height: 0;
    padding: 5px 15px;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .tooltip-trigger {
    bottom: 5px;
    color: $gray-light;
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    margin-left: 7px;
    position: relative;
    text-decoration: underline;
  }
}

.table-import-modal {
  .table-data-textarea {
    display: block;
    min-height: 200px;
    width: 100%;
  }
}
