@use '../../../../scss/vars' as *;

#address-wrap {
  position: relative;

  .autocomplete-dropdown-container {
    background: $gray-lightest;
    left: 0;
    position: absolute;
    right: 0;
    top: 38px;

    .suggestion-item {
      border: 1px solid $gray-bg;
      border-top: 0;
      color: #495057;
      cursor: pointer;
      font-size: 0.875rem;
      padding: 0.375rem 0.75rem;

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .active {
      background-color: $primary;
      color: #fff;
    }
  }
}
