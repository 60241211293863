@use '../../../../scss/vars' as *;

.footnotes {
  padding-top: 10px;

  .footnotes-header {
    padding: 5px 0;

    &::after {
      clear: both;
      content: '';
      display: block;
    }

    h6 {
      float: left;
      margin: 4px 0 0;
    }

    button {
      color: $gray-med;
      float: right;

      &:hover {
        color: $primary;
      }

      &:focus {
        outline: none;
      }

      &.footnotes-remove {
        .svg-inline--fa {
          transform: rotate(45deg);
        }
      }
    }
  }

  .footnotes-content {
    .editor-footnotes {
      font-size: 0.875rem;

      p {
        margin-bottom: 0.75rem;
      }
    }
  }
}
