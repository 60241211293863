@use '../../scss/vars' as *;

#app-header {
  background: $brand;
  color: #fff;
  left: 0;
  padding: 6px 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 3;

  .back-link {
    color: #fff;
    float: left;
    font-weight: 500;
    margin-top: 8px;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
      text-decoration: none;
    }

    .svg-inline--fa {
      bottom: 1px;
      height: 1.3rem;
      margin-right: 3px;
      position: relative;
      width: 1.3rem;
    }
  }

  h1 {
    display: inline-block;
    font-size: 1.25rem;
    margin: 10px 0;
  }
}
