.canvas-component.canvas-component-media {
  button {
    display: block;
    margin: 44px auto;
  }

  .description {
    margin-top: 10px;
  }
}
