@use '../../../../scss/vars' as *;

.canvas-component.canvas-component-documents {
  button.add-document {
    display: block;
    margin: 44px auto;
  }

  #documents-list {
    .doc-item {
      padding: 12px;

      &:nth-child(even) {
        background: #f4f7fb;
      }

      &:hover {
        .right-buttons {
          display: block;
        }
      }

      .file-icon {
        color: $gray-med;
        height: 2rem;
        margin-right: 20px;
        vertical-align: top;
        width: 2rem;
      }

      span.doc-title {
        display: inline-block;
        font-weight: 500;
        padding-right: 20px;
        width: calc(100% - 115px);
      }

      .right-buttons {
        display: none;
        float: right;

        .doc-link,
        .doc-download {
          color: $gray-med;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
