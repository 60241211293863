@use '../../../../scss/vars' as *;

.canvas-component.canvas-component-cash-flow {
  .container {
    padding: 0;
  }

  .table {
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    margin-bottom: 0;
    min-width: inherit !important;

    colgroup col {
      width: auto !important;
    }

    thead {
      background: #f4f7fb;

      tr {
        border: 1px solid #dee2e6;

        th {
          padding: 8px;
        }
      }
    }

    td {
      border-right: 1px solid #dee2e6;
      height: 49px;
      overflow: hidden;
      padding: 5px;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: 199px;

      .btn {
        color: #444;
        padding: 10px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          color: $primary;
        }
      }

      &.p-0 {
        max-width: 80px;
        width: 40px;
      }

      .dx-g-bs4-fixed-block {
        text-align: center;
      }

      &.period {
        background-color: #f4f7fb;
        font-weight: 500;
        pointer-events: none;
      }
    }
  }
}
