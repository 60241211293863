@use '../../../../scss/vars' as *;

.canvas-component.canvas-component-metrics {
  min-height: 158px;

  button {
    display: block;
    margin: 44px auto;
  }

  .metric-column {
    padding: 15px;
    text-align: center;

    .svg-inline--fa {
      color: $primary;
      height: 2.5rem;
      margin-bottom: 12px;
      width: 2.5rem;
    }

    .label {
      color: $gray-med;
      font-size: 0.825rem;
      font-weight: 500;
      margin-bottom: 5px;
      text-transform: uppercase;

      .label-info-icon {
        color: $gray-light;
        height: 1rem;
        margin: -3px 0 0 3px;
        width: 1rem;
      }
    }
  }
}
